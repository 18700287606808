import { SIZE64, SIZE64_MAX_FLOAT_DIGITS_COUNT, useCombinedRef } from "@fm-frontend/utils";
import {
    ChangeEvent,
    forwardRef,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { useWidth } from "../../../hooks";
import { HintVariant, HintVariantComponents } from "../../common/HintVariantComponents";
import { getClickHelper } from "../../helper";
import { Icons } from "../../icons";
import { TextSmall } from "../../typography";
import { InputProps, InputVariant, NumericInputProps, TradeAmountInputProps } from "./Input.types";
import {
    BasicInput,
    BasicInput as MinimumInput,
    BasicLabel,
    Container,
    InputGroup,
    SimpleInput,
    SimpleLabel,
} from "./styled";

const InputVariantComponents: {
    [Key in InputVariant]: StyledComponent<"input", DefaultTheme, { isError?: boolean }, never>;
} = {
    basic: BasicInput,
    simple: SimpleInput,
    minimum: MinimumInput,
};

const LabelVariantComponents: {
    [Key in InputVariant]: StyledComponent<"label", DefaultTheme, { offset: number }, never>;
} = {
    basic: BasicLabel,
    simple: SimpleLabel,
    minimum: styled.label`
        display: none;
    `,
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        variant,
        label,
        labelHelper,
        labelHelperOptions,
        name,
        hint,
        error,
        disabled,
        _size = "large",
        showError = true,
    } = props;

    const [hintVariant, setHintVariant] = useState<{
        variant: HintVariant;
        text?: string | ReactNode;
    }>({
        variant: "hint",
    });

    useEffect(() => {
        if (error) {
            setHintVariant({ variant: "error", text: error });
        } else {
            setHintVariant({ variant: "hint", text: hint });
        }
    }, [error, hint]);

    const InputComponent = InputVariantComponents[variant];
    const HintComponent = HintVariantComponents[hintVariant.variant];
    const LabelComponent = LabelVariantComponents[variant];

    const { offset, ref: labelRef } = useWidth({ label });

    return (
        <Container size={_size} isDisabled={disabled}>
            <InputGroup>
                {label && (
                    <LabelComponent offset={offset} ref={labelRef} htmlFor={name}>
                        {label}
                        {getClickHelper(labelHelper, labelHelperOptions)}
                    </LabelComponent>
                )}
                <InputComponent
                    data-field-group
                    ref={ref}
                    isError={hintVariant.variant === "error"}
                    {...props}
                />
            </InputGroup>
            {hintVariant.text && showError && (
                <HintComponent>
                    <TextSmall>{hintVariant.text}</TextSmall>
                </HintComponent>
            )}
        </Container>
    );
});

const isDigit = (char: string) => char >= "0" && char <= "9";
const isDecimalChar = (char: string) => isDigit(char) || char === ".";
const convertStringToFormattedDecimalString = (str: string, decimalScale: number) => {
    let formattedValue;
    try {
        const decimalSeparatorIndex = str.indexOf(".");
        const hasDecimalSeparator = decimalSeparatorIndex !== -1;
        const fractionPart = !hasDecimalSeparator ? "" : str.slice(decimalSeparatorIndex + 1);
        const normalizedFractionPart =
            fractionPart.length > 0
                ? [...fractionPart].filter(isDigit).join("").slice(0, decimalScale)
                : "";
        const normalizedInputStr = !hasDecimalSeparator
            ? str
            : `${str.slice(0, decimalSeparatorIndex)}.${normalizedFractionPart}`;
        const size64Num = SIZE64.fromString(normalizedInputStr);
        formattedValue =
            size64Num === null ? "" : SIZE64.toFormattedDecimalString(size64Num, decimalScale, 0);
        // for cases when user types:
        // 123. -> 123.
        // 123.a -> 123.
        if (hasDecimalSeparator) {
            if (!formattedValue.includes(".")) {
                formattedValue += ".";
            }
            formattedValue = `${formattedValue.slice(
                0,
                formattedValue.indexOf("."),
            )}.${normalizedFractionPart}`;
        }
    } catch {
        formattedValue = "";
    }

    return formattedValue;
};

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(
    (props, forwardedRef) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const combinedRef = useCombinedRef(forwardedRef, inputRef);
        const { onChange, value, ...inputProps } = props;
        const decimalScale =
            "decimalScale" in props && props.decimalScale !== undefined
                ? props.decimalScale
                : SIZE64_MAX_FLOAT_DIGITS_COUNT;
        // this state needs if we use "register" method of react-hook-form
        // because "register" does not rerender Input on valueChange
        // but we should control Input.value because we changes it (format to 1,234,567)
        const [valueCopy, setValueCopy] = useState(
            convertStringToFormattedDecimalString(value ? String(value) : "", decimalScale),
        );
        // update copyValue if outer value differs from local state (component consumer )
        if (
            typeof value === "string" &&
            convertStringToFormattedDecimalString(value, decimalScale) !== valueCopy
        ) {
            setValueCopy(convertStringToFormattedDecimalString(value, decimalScale));
        }

        const [inputChangeCounter, inputChanged] = useReducer((x) => x + 1, 0);

        const decimalCharsCountFromCursorToEnd = useRef(0);

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            let {
                target: { value: rawValue },
            } = event;
            let currentCursorPosition = inputRef.current?.selectionStart ?? rawValue.length;

            if (valueCopy.length - rawValue.length === 1) {
                const removedIndex = [...valueCopy].findIndex((char, i) => char !== rawValue[i]);
                if (valueCopy[removedIndex] === ",") {
                    // if only thousand separator was removed (,) then remove previous digit
                    // and move currentCursorPosition lefter
                    rawValue = [...rawValue].toSpliced(removedIndex - 1, 1).join("");
                    currentCursorPosition -= 1;
                }
            }

            const formattedValue = convertStringToFormattedDecimalString(rawValue, decimalScale);
            decimalCharsCountFromCursorToEnd.current = [
                ...rawValue.slice(currentCursorPosition),
            ].reduce((acc, char) => {
                if (isDecimalChar(char)) {
                    return acc + 1;
                }
                return acc;
            }, 0);

            setValueCopy(formattedValue);
            inputChanged();
            event.target.value = formattedValue;
            onChange?.(event);
        };

        // update cursor position in input after value in input has been changed changed
        useLayoutEffect(() => {
            let count = 0;
            let charsCountFromCursorToEnd = [...valueCopy].reverse().findIndex((char) => {
                if (count === decimalCharsCountFromCursorToEnd.current) {
                    return true;
                }
                if (isDecimalChar(char)) {
                    count++;
                }
            });
            charsCountFromCursorToEnd =
                charsCountFromCursorToEnd === -1 ? valueCopy.length : charsCountFromCursorToEnd;

            const cursorPosition = valueCopy.length - charsCountFromCursorToEnd;
            inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
        }, [inputChangeCounter, valueCopy]);

        return (
            <Input {...inputProps} onChange={handleChange} value={valueCopy} ref={combinedRef} />
        );
    },
);

const SwapButton = styled.button`
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    background: transparent;

    > div {
        text-align: left;
    }

    :hover svg path {
        fill-opacity: 0.8;
    }

    :focus-visible {
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
`;
const AssetLabel = styled.div<{ $selected: boolean }>`
    font-size: 8px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: -0.04px;
    color: ${(p) => (p.$selected ? p.theme.colors.ui100 : p.theme.colors.ui32)};
`;
const InnerSwapSwitch = ({
    value,
    onChange,
    assetCurrency,
    balanceCurrency,
}: {
    value: string;
    onChange: (value: string) => void;
    assetCurrency: string;
    balanceCurrency: string;
}) => {
    return (
        <SwapButton
            type="button"
            onClick={() => onChange(value === assetCurrency ? balanceCurrency : assetCurrency)}
        >
            <Icons.VerticalSwap />
            <div>
                <AssetLabel $selected={value === assetCurrency}>{assetCurrency}</AssetLabel>
                <AssetLabel $selected={value === balanceCurrency}>{balanceCurrency}</AssetLabel>
            </div>
        </SwapButton>
    );
};
const TradeAmountLabel = styled(SimpleLabel)`
    left: 8px;
    z-index: 1;
`;
export const TradeAmountInput = forwardRef<HTMLInputElement, TradeAmountInputProps>(
    (props, ref) => {
        const {
            name,
            hint,
            error,
            disabled,
            assetCurrency,
            balanceCurrency,
            selectedTradeCurrency,
            showError = true,
            decimalScale,
        } = props;
        const { onTradeCurrencyChange, onChange, ...inputProps } = props;

        const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
            if (ev.target.value && typeof ev.target.value === "string") {
                ev.target.value = [...ev.target.value].filter((char) => char !== ",").join("");
            }
            onChange?.(ev);
        };

        const [hintVariant, setHintVariant] = useState<{
            variant: HintVariant;
            text?: string | ReactNode;
        }>({
            variant: "hint",
        });

        useEffect(() => {
            if (error) {
                setHintVariant({ variant: "error", text: error });
            } else {
                setHintVariant({ variant: "hint", text: hint });
            }
        }, [error, hint]);

        const HintComponent = HintVariantComponents[hintVariant.variant];

        return (
            <Container size="medium" isDisabled={disabled}>
                <InputGroup>
                    <TradeAmountLabel offset={0} htmlFor={name}>
                        <InnerSwapSwitch
                            value={selectedTradeCurrency}
                            onChange={onTradeCurrencyChange}
                            assetCurrency={assetCurrency}
                            balanceCurrency={balanceCurrency}
                        />
                    </TradeAmountLabel>
                    <NumericInput
                        variant="simple"
                        key={selectedTradeCurrency}
                        ref={ref}
                        showError={false}
                        {...inputProps}
                        onChange={handleChange}
                        decimalScale={decimalScale}
                        _size="medium"
                    />
                </InputGroup>
                {hintVariant.text && showError && (
                    <HintComponent>
                        <TextSmall>{hintVariant.text}</TextSmall>
                    </HintComponent>
                )}
            </Container>
        );
    },
);
