import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IconButton } from "../buttons";
import { Icons } from "../icons";
import { HStack } from "../layout";

const Layout = styled.div`
    overflow: hidden;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0px -1px 0px 0px ${(p) => p.theme.colors.ui12} inset,
        0px 0px 0px 1px ${(p) => p.theme.colors.ui12} inset,
        0px 1px 2px 0px ${(p) => p.theme.colors.ui4};
`;

const Header = styled(HStack)`
    padding: 12px;
    gap: 12px;
    align-items: center;
    cursor: pointer;

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

const HeaderContent = styled.div`
    flex: 1;
`;

const HeaderIcon = styled(Icons.Expand)<{ $isExpanded: boolean }>`
    transform: rotate(${(p) => (p.$isExpanded ? 0 : -90)}deg);
    transition: transform linear 100ms;
`;

export const Accordion = ({
    header,
    content,
    detailsPageUrl,
    defaultExpanded,
    onExpandedChange,
}: {
    header: JSX.Element | string;
    content: JSX.Element | string;
    detailsPageUrl?: string;
    defaultExpanded?: boolean;
    onExpandedChange?: (expanded: boolean) => void;
}) => {
    const history = useHistory();
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const handleExpandedChange = () => {
        const expanded = !isExpanded;
        onExpandedChange?.(expanded);
        setIsExpanded(expanded);
    };

    return (
        <Layout>
            <Header onClick={handleExpandedChange}>
                <HeaderIcon $isExpanded={Boolean(isExpanded)} />
                <HeaderContent>{header}</HeaderContent>
                {detailsPageUrl && (
                    <IconButton
                        variant="plain"
                        Icon={Icons.Greater}
                        onClick={() => {
                            history.push(detailsPageUrl);
                        }}
                    />
                )}
            </Header>
            {isExpanded && content}
        </Layout>
    );
};
