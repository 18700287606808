import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "../common";
import { DelayMode } from "../Popover";
import { PSmall, PXSmall } from "../typography";
import { BadgeSize, BadgeVariant } from "./types";

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const clientIdCss = css`
    padding: 0 2px;
    min-width: unset;

    background-color: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.ui12};

    * {
        color: ${(p) => p.theme.colors.ui72};
    }
`;

const positiveCss = css`
    background-color: ${(p) => p.theme.colors.positive8};

    * {
        color: ${(p) => p.theme.colors.positive100};
        font-weight: 600;
    }
`;

const processCss = css`
    background-color: ${(p) => p.theme.colors.process8};

    * {
        color: ${(p) => p.theme.colors.process100};
        font-weight: 600;
    }
`;

const brandCss = css`
    background-color: ${(p) => p.theme.colors.brand8};

    * {
        color: ${(p) => p.theme.colors.brand100};
        font-weight: 600;
    }
`;

const attentionCss = css`
    background-color: ${(p) => p.theme.colors.attention8};
    * {
        color: ${(p) => p.theme.colors.attention100};
        font-weight: 600;
    }
`;

const infoCss = css`
    background-color: ${(p) => p.theme.colors.ui4};
    * {
        color: ${(p) => p.theme.colors.ui52};
        font-weight: 600;
    }
`;

const negativeCss = css`
    background-color: ${(p) => p.theme.colors.negative8};
    * {
        color: ${(p) => p.theme.colors.negative100};
        font-weight: 600;
    }
`;

const disabledCss = css`
    background-color: ${(p) => p.theme.colors.ui8};
    color: ${(p) => p.theme.colors.ui20};
`;

const largeBadgeCss = css`
    padding: 4px 8px;
    border-radius: 12px;
`;
const smallBadgeCss = css`
    padding: 0 4px;
    border-radius: 4px;
`;

export const Wrapper = styled.div<{ disabled?: boolean; variant: BadgeVariant; size: BadgeSize }>`
    position: relative;
    display: flex;
    min-width: 18px;
    width: fit-content;
    border: none;

    ${(p) => {
        switch (p.size) {
            case "large": {
                return largeBadgeCss;
            }
            case "small": {
                return smallBadgeCss;
            }
            default:
                return null;
        }
    }}

    ${(p) => {
        switch (p.variant) {
            case "positive": {
                return positiveCss;
            }
            case "process": {
                return processCss;
            }
            case "brand": {
                return brandCss;
            }
            case "attention": {
                return attentionCss;
            }
            case "negative": {
                return negativeCss;
            }
            case "smallClientId":
            case "clientId": {
                return clientIdCss;
            }
            case "info": {
                return infoCss;
            }
            default: {
                return null;
            }
        }
    }}

    ${(p) => p.disabled && disabledCss}
`;

export const Badge: React.VFC<{
    value: ReactNode;
    tooltip?: string | null;
    delayMode?: DelayMode;
    disabled?: boolean;
    variant: BadgeVariant;
    size?: BadgeSize;
    className?: string;
}> = ({ value, tooltip, delayMode, disabled, variant, size = "small", className }) => {
    const tooltipValue = disabled || !tooltip ? null : tooltip;
    const Label = variant === "smallClientId" ? PXSmall : PSmall;

    return (
        <Container className={className}>
            <Tooltip content={tooltipValue} delayMode={delayMode}>
                <Wrapper variant={variant} disabled={disabled} size={size}>
                    <Label>{value}</Label>
                </Wrapper>
            </Tooltip>
        </Container>
    );
};
